function belt({ color, scale, stripe }) {
    return (
        <div className={color} style={{ transform: `scale(${scale})`}}>
            <svg viewBox="0 0 133 76" xmlns="http://www.w3.org/2000/svg">
                <rect x="9.48193" width="113.786" height="28.4464" />
                <rect width="9.48214" height="18.9643" transform="matrix(-1 0 0 1 9.48193 4.74097)" />
                <rect width="9.48214" height="18.9643" transform="matrix(-1 0 0 1 132.75 4.74097)" />
                <rect x="85.3394" y="28.4463" width="9.48214" height="18.9643" />
                <rect x="94.8213" y="28.4463" width="9.48214" height="28.4464" />
                <rect x="104.304" y="37.9285" width="9.48214" height="28.4464" />
                <rect x="113.786" y="47.4104" width="9.48214" height="28.4464" />
                <rect x="123.268" y="56.8928" width="9.48214" height="18.9643" />
                <rect width="9.48214" height="18.9643" transform="matrix(-1 0 0 1 47.4106 28.4463)" />
                <rect width="9.48214" height="28.4464" transform="matrix(-1 0 0 1 37.9287 28.4463)" />
                <rect width="9.48214" height="28.4464" transform="matrix(-1 0 0 1 28.4463 37.9285)" />
                <rect width="9.48214" height="28.4464" transform="matrix(-1 0 0 1 18.9644 47.4104)" />
                <rect width="9.48214" height="18.9643" transform="matrix(-1 0 0 1 9.48193 56.8928)" />
                <rect className={stripe} x="6" y="9" width="120" height="10"/>
            </svg>
        </div>
    );
}

export default belt;