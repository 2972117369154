import Belt from '../components/belt';

import { useState, useRef, useMemo, useEffect } from 'react';

import useSound from 'use-sound';
import switchClick from './../assets/switch-click.mp3';


import Container from '../components/container';
import VerticalCenteredContainer from '../components/vertical-centered-container';
import Nav from '../components/nav';
import Background from '../components/background';
import PrimaryButton from '../components/primary-button';
import ContainerLarge from '../components/container-lg';
import Scrollbar from '../components/scrollbar';

import requireAuth from '../scripts/requireAuth';
import HomeModal from '../components/home-modal';
import { useLocation, useNavigate } from 'react-router-dom';


import { getFirestore, collection, getDocs, query, orderBy, where } from 'firebase/firestore';

import beltMappings from '../scripts/data/belt-mappings';
import getAccountType from '../scripts/getAccountType';

import gameLogoSound from '../assets/game-logo.mp3';

function Home({ lightBg, setLightBg }) {

    const [scrollX, setScrollX] = useState(0);

    const scrollRef = useRef(null);

    const onScroll = () => {
        setScrollX(scrollRef.current.scrollLeft);
    };


    //const baseWidth = ((containerWidth / 2) + 20 + (128 / 2)) - (containerWidth / 2);
    let containerWidth = Math.min(window.innerWidth, 512); //32rem = 512px
    const baseWidth = (containerWidth / 2) - (containerWidth / 2); //Happens that padding is 50vw
    const intervalWidth = 128 + (20 * 2);


    const [belts, setBelts] = useState([]);

    const beltIndex = useMemo(() => {
        return Math.min(Math.floor((scrollX - baseWidth) / intervalWidth), (belts.length > 0 ? (belts.length - 1) : 0));
    }, [scrollX]);


    const rendered = useRef(false);

    const [playGameLogo, gameLogoOptions] = useSound(gameLogoSound, { volume: 0.15 });

    const [playSwitchClick] = useSound(switchClick, { volume: 0.25 });

    useEffect(() => {
        if (!rendered.current) { //Prevent click on first render
            rendered.current = true;
            return;
        }
        playSwitchClick();

        let beltStyle = beltMappings[belts[beltIndex].color];
        if ((beltStyle['light-bg'] || false) != lightBg) {
            if (!lightBg) {
                playGameLogo();
            } else {
                gameLogoOptions.stop();
            }

            setLightBg(!lightBg);
        }
    }, [beltIndex]);

    const navigate = useNavigate();

    useEffect(() => {
        async function getRanks() {
            let db = getFirestore();
            let ranksRef = collection(db, 'ranks');
            let ranks = await getDocs(query(ranksRef, where('accountType', '==', (await getAccountType())), orderBy('index')));

            console.log(ranks.docs);
            let belts = [];

            for (let rank of ranks.docs) {
                for (let belt of rank.data().belts) {
                    belts.push({
                        color: belt,
                        rank: rank.id
                    });
                }
            }

            setBelts(belts);
        }

        getRanks();
    }, []);

    useEffect(() => {
        return () => {
            setLightBg(false);
        }
    }, []);

    let handleRankSelect = () => {
        gameLogoOptions.stop();
        navigate(`/rank/${belts[beltIndex].rank}`);
    };

    return (
        <>
            <div className="flex flex-col h-full">
                <div>
                    <Nav />
                </div>
                <div className='flex-grow'>
                    <Background />
                </div>
            </div>
            <VerticalCenteredContainer>
                <div className='z-10 relative'>
                    <Container className='text-4xl mb-12 text-center'>Select your rank</Container>
                    <ContainerLarge>
                        <Scrollbar type='horizontal' className='overflow-x-auto snap-proximity snap-x mb-9' style={{
                            WebkitMaskImage: 'linear-gradient(to right, transparent, black 60px, black calc(100% - 60px), transparent)'
                        }} ref={scrollRef} onScroll={onScroll}>
                            <div className='flex w-fit h-20 px-[50%]'>
                                {
                                    belts.map((belt, index) => {
                                        let containerWidth = scrollRef.current?.clientWidth;
                                        let leftOfBelt = (containerWidth / 2) + (intervalWidth * index);
                                        let targetScrollX = leftOfBelt + (intervalWidth / 2) - (containerWidth / 2);
                                        let scaleCoefficient = 0.75;
                                        if (scrollX < (leftOfBelt + intervalWidth) && scrollX > (leftOfBelt - containerWidth)) {
                                            //Within range
                                            let difference = scrollX - targetScrollX;
                                            let maxScrollInViewWidth = (containerWidth / 2) + (intervalWidth / 2);
                                            scaleCoefficient = (((scaleCoefficient - 1) / maxScrollInViewWidth) * Math.abs(difference)) + 1;
                                        }
                                        let beltStyle = beltMappings[belt.color];

                                        return (<div className='flex-shrink-0 snap-center w-32 mx-5' key={index}>
                                            <Belt color={beltStyle?.color} scale={scaleCoefficient.toFixed(2)} stripe={beltStyle?.stripe} />
                                        </div>);
                                    })
                                }
                            </div>
                        </Scrollbar>
                    </ContainerLarge>
                    <Container>
                        <PrimaryButton className='mb-12' onClick={handleRankSelect}> SELECT</PrimaryButton >
                    </Container >
        <HomeModal />
                </div >
            </VerticalCenteredContainer >
        </>
    );
}

export default requireAuth(Home);