import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import reportWebVitals from './reportWebVitals';

import Routes from './routes';


import { Amplify } from 'aws-amplify';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFederatedSigninObject } from './scripts/signinFederatedBBP';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCIxpUrvXnze6Vy-Fd7SSCERgKBSOUcnQg",
  authDomain: "kovars-bunkai.firebaseapp.com",
  projectId: "kovars-bunkai",
  storageBucket: "kovars-bunkai.appspot.com",
  messagingSenderId: "1092061203289",
  appId: "1:1092061203289:web:590295c2f84ba8d6de1a48"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

Amplify.configure({
  Auth: { //Using dev resources instead
    identityPoolId: "us-west-1:709db042-b224-42e3-acbd-3e7128e81d15",
    region: "us-west-1",
    userPoolId: "us-west-1_Hpgb3Gzmf", //Can this be removed?
    userPoolWebClientId: "16mcdnv3uin9vte549keejciea", //Can this be removed?
    refreshHandlers: {
      'securetoken.google.com/kovars-bunkai' : getFederatedSigninObject
    }
  },
  API: {
    endpoints: [
      {
        name: 'bbp',
        endpoint: 'https://api.kovarslaguna.com',
        region: 'us-west-1'
      }
    ]
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Routes />)

reportWebVitals();
