let loginErrors = {
    // Login
    'auth/invalid-email' : 'Please use a valid email',
    'auth/user-disabled' : 'This account has been disabled, please contact support',
    'auth/wrong-password' : 'Wrong password, please try again',
    'auth/user-not-found' : 'Email not found, please try again',
};

let signupErrors = {
    //Signup
    'auth/email-already-in-use' : 'Email already in use, please try again',
    'auth/invalid-email' : 'Please use a valid email',
    'auth/weak-password' : 'Password must be at least 6 characters long',
}

export function getLoginError(errorCode) {
    return loginErrors[errorCode] || `Something went wrong! Error code: ${errorCode}`;
}

export function getSignupError(errorCode) {
    return signupErrors[errorCode] || `Something went wrong! Error code: ${errorCode}`;
}