import ContainerLarge from "./container-lg";

function Background({ className }) {
    return (
        <ContainerLarge className={`h-full ${className || ''}`}>
            <div className="relative h-full">
                <div className="w-full absolute bottom-0 z-0">
                    <svg viewBox="0 0 430 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 216.211L430 0.711182V320H0V216.211Z" fill="#B91C1C" />
                        <rect y="209.067" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="10" y="204.089" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="20" y="199.111" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="30" y="194.133" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="40" y="189.156" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="50" y="184.178" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="60" y="179.2" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="70" y="174.222" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="80" y="169.244" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="90" y="164.267" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="100" y="159.289" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="110" y="154.311" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="120" y="149.333" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="130" y="144.355" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="140" y="139.378" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="150" y="134.4" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="160" y="129.422" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="170" y="124.444" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="180" y="119.467" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="190" y="114.489" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="200" y="109.511" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="210" y="104.533" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="220" y="99.5554" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="230" y="94.5776" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="240" y="89.6001" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="250" y="84.6221" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="260" y="79.6443" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="270" y="74.6667" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="280" y="69.689" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="290" y="64.7112" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="300" y="59.7334" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="310" y="54.7556" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="320" y="49.7776" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="330" y="44.8" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="340" y="39.8223" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="350" y="34.8445" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="360" y="29.8667" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="370" y="24.8889" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="380" y="19.9111" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="390" y="14.9333" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="400" y="9.95557" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="410" y="4.97778" width="10" height="7.11111" fill="#B91C1C" />
                        <rect x="420" width="10" height="7.11111" fill="#B91C1C" />
                    </svg>
                </div>
            </div>
        </ContainerLarge>
    );
}

export default Background;