import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuth } from 'firebase/auth';

import checkAuthStatus from './helpers/checkAuthStatus';

import waitForAuthLoad from './waitForAuthLoad';

const requireNoAuth = (WrappedComponent) => {
    const ComponentWithAuthentication = (props) => {
        const navigate = useNavigate();

        const [authLoaded, setAuthLoaded] = useState(false);

        useEffect(() => {
            async function init() {
                await waitForAuthLoad();
                console.log('checking no auth');

                if (getAuth().currentUser) {
                    console.log('already logged in');
                    navigate('/home');
                } else {
                    setAuthLoaded(true);
                }
            }

            init();
        }, []);
        return (authLoaded ? <WrappedComponent {...props} /> : <div />);
    };

    return ComponentWithAuthentication;
};

export default requireNoAuth;