function Input({ label, type, onChange, onSubmit, className }) {
    //Input with label
    return (
        <div className={className}>
            <label>
                <div className="text-2xl pb-2">{label}</div>
                <input
                    type={type}
                    className='appearance-none bg-zinc-200 text-zinc-900 text-2xl py-0.5 px-3 focus:outline-none w-full selection:bg-red-700 selection:text-zinc-200'
                    onChange={(e) => onChange(e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') { onSubmit(); } }}
                    />
            </label>
        </div>
    );
}

export default Input;