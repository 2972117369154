import { getAuth, onAuthStateChanged } from "firebase/auth";

async function waitForAuthLoad () {
    const auth = getAuth();
    let unsubscribe;
    await new Promise(resolve => {
        unsubscribe = onAuthStateChanged(auth, resolve);
    });
    unsubscribe(); //Unsubscribe after auth is loaded
}

export default waitForAuthLoad;