function ProgressBar({ className, style, color }) {
    return (
        <div className={className || ''} style={style}>
            <div className='flex items-center h-3'>
                <div className={`h-2 w-1 ${color || ''}`}></div>
                <div className={`h-full grow ${color || ''}`}></div>
                <div className={`h-2 w-1 ${color || ''}`}></div>
            </div>
        </div>
    );
}

export default ProgressBar;