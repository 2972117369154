import { getFirestore, doc, getDoc, updateDoc, increment } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import dayjs from 'dayjs';

export default async function (navigate) { //Inject useNavigate. Doesn't follow conventional hooks practices, so may need to change lateer on    
    let db = getFirestore();
    let docRef = doc(db, 'users', getAuth().currentUser.uid);

    let lastLoginDate = (await getDoc(docRef)).data().lastLoginDate;

    if (lastLoginDate == dayjs().format('YYYY-MM-DD')) {
        return { continue : true };
    }

    await updateDoc(docRef, {
        lastLoginDate: dayjs().format('YYYY-MM-DD'),
        points: increment(10)
    })

    //Add -- if new title, show achievement page
    let points = (await getDoc(docRef)).data().points;
    if (points % 50 == 0) {
        navigate('/new-title');
        return { continue : false };
    } else {
        console.log('Redirecting to home with points');
        navigate('/home', {
            state : { earnedPoints : true }
        });
        return { continue : false };
    }
}