import Container from '../components/container';

function ModalContainer ({ children, className }) {
    return (
        <Container>
            <div className={`bg-stone-500 drop-shadow-retro-dark-lg pt-5 px-9 ${className || ''}`}>
                { children }
            </div>
        </Container>
    )
}

export default ModalContainer;