import buttonClickSound from '../assets/button-click2.mp3';
import useSound from 'use-sound';

function PrimaryButton({ className, children, onClick, disabled }) {
    let [playButtonClick] = useSound(buttonClickSound, { volume: 0.25 });

    let handleClick = () => {
        if (!disabled) {
            playButtonClick();        
            onClick();
        }
    };

    return (
        <button className={`text-2xl px-20 py-2 mx-auto block drop-shadow-retro-dark-md ${disabled ? 'cursor-auto bg-red-900 text-neutral-400' : 'bg-red-700 hover:bg-red-800'} ${className || ''}`} onClick={handleClick}>{children}</button>
    );
}

export default PrimaryButton;