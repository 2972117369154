function Arrow({ className }) {
    return (
        <svg className={className} width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="2.14286" height="2.85714" transform="matrix(-1 0 0 1 8.57129 5.14282)" fill="#E4E4E7" />
            <rect width="2.14286" height="2.85714" transform="matrix(-1 0 0 1 10.7144 3.42847)" fill="#E4E4E7" />
            <rect x="4.28564" y="3.42847" width="2.14286" height="2.85714" fill="#E4E4E7" />
            <rect width="2.14286" height="2.85714" transform="matrix(-1 0 0 1 12.8569 1.71436)" fill="#E4E4E7" />
            <rect x="2.14307" y="1.71436" width="2.14286" height="2.85714" fill="#E4E4E7" />
            <rect width="2.14286" height="2.85714" transform="matrix(-1 0 0 1 15 0)" fill="#E4E4E7" />
            <rect width="2.14286" height="2.85714" fill="#E4E4E7" />
        </svg>

    );
}

export default Arrow;