import { forwardRef } from "react";

//type is vertical or horizontal
function Scrollbar ({ className, style, onScroll, children, type }, ref) {
    return (
        <div className={`scrollbar scrollbar-thumb-zinc-400 scrollbar-track-stone-600 ${type == 'vertical' ? 'scrollbar-w-1.5' : 'scrollbar-h-1.5' } ${className }`} style={style} ref={ref} onScroll={onScroll}>
            {children}
        </div>
    );
}

export default forwardRef(Scrollbar);