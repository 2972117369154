import Input from "../components/input";
import PrimaryButton from "../components/primary-button";
import GoogleButton from "react-google-button";

import { useState } from "react";

function AuthForm({ onSubmitUserPassword, onSubmitGoogle, googleText, className, disabled }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    return (
        <div className={className}>
            <GoogleButton type="light" label={googleText} className="mx-auto" onClick={onSubmitGoogle} />
            <div className="my-5 text-2xl text-center">or</div>
            <Input label='Email' className='mb-2' onChange={(val) => setEmail(val)} onSubmit={() => onSubmitUserPassword(email, password)} />
            <Input label='Password' className='pb-9' type='password' onChange={(val) => setPassword(val)} onSubmit={() => onSubmitUserPassword(email, password)} />
            <PrimaryButton disabled={disabled} onClick={() => onSubmitUserPassword(email, password)}>LOG IN</PrimaryButton>
        </div>
    );
}

export default AuthForm;