function VerticalCenteredContainer({ className, children}) {
    return (
        <div className={`absolute w-full h-full top-0 left-0 ${className || ''}`}>
            <div className="flex justify-center items-center h-full">
                <div className="w-full">{children}</div>
            </div>
        </div>
    );
}

export default VerticalCenteredContainer;