import { getAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import createUser from '../scripts/createUser';
import signinFederatedBBP from '../scripts/signinFederatedBBP';
import updatePoints from '../scripts/updatePoints';
import getAccountType from '../scripts/getAccountType';

function PostAuthHandler({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const fromLoginSignup = useRef(false);

  useEffect(() => {
    console.log('PostAuthHandler: Subscribing to auth state changes');

    const unsubscribe = getAuth().onAuthStateChanged(async user => {
      if (user) {
        console.log('PostAuthHandler: User signed in');

        await signinFederatedBBP();

        await createUser(); //Idempotent

        if ((await getAccountType()) == null) {
          console.log('PostAuthHandler: Navigating to /welcome');
          navigate('/welcome');
          return;
        }

        let updatePointsResult = await updatePoints(navigate);

        if (!updatePointsResult.continue) {
          return;
        }


        if (fromLoginSignup.current) {
          navigate('/home');
        }
      } else {
        fromLoginSignup.current = true;
      }
    });
    return unsubscribe;
  }, []);
  return children;
}

export default PostAuthHandler;