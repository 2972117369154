export default {
    white: { color: 'fill-neutral-300' },
    gold: { color: 'fill-yellow-400' },
    orange: { color: 'fill-orange-400' },
    green: { color: 'fill-green-400' },
    purple: { color: 'fill-purple-500' },
    blue: { color: 'fill-blue-600' },
    red: { color: 'fill-red-600' },
    'adv-red' : { color: 'fill-red-600', stripe: 'fill-zinc-900' },
    'beg-brown': { color: 'fill-amber-900', stripe: 'fill-zinc-200' },
    brown: { color: 'fill-amber-900' },
    'adv-brown': { color: 'fill-amber-900', stripe: 'fill-zinc-900' },
    'jr-black-white' : { color: 'fill-stone-950', stripe: 'fill-zinc-200', 'light-bg' : true },
    'jr-black-blue' : { color: 'fill-stone-950', stripe: 'fill-blue-600', 'light-bg' : true },
    'jr-black-red' : { color: 'fill-stone-950', stripe: 'fill-red-600', 'light-bg' : true },
    'black' : { color: 'fill-stone-950', 'light-bg' : true },
}