import Root from './pages/root';
import Login from './pages/login';
import Signup from './pages/signup';
import Home from './pages/home';
import Rank from './pages/rank';
import NotFound from './pages/not-found';
import Welcome from './pages/welcome';
import Account from './pages/account';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useState } from 'react';
import PostAuthHandler from './components/post-auth-handler';
import NewTitle from './pages/new-title';

function Index() {
    const [lightBg, setLightBg] = useState(false);

    return (
        <BrowserRouter>
            <PostAuthHandler>
                <div className={`${lightBg ? 'bg-stone-700' : 'bg-zinc-900'} transition-colors duration-300 text-zinc-200 h-full pt-12`}>
                    <Routes>
                        <Route path="/" element={<Root />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/home" element={<Home lightBg={lightBg} setLightBg={setLightBg} />} />
                        <Route path="/rank/:rankID" element={<Rank />} />
                        <Route path="/new-title" element={<NewTitle />} />
                        <Route path="/welcome" element={<Welcome />} />
                        <Route path="/account" element={<Account />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </PostAuthHandler>
        </BrowserRouter>
    );
}

export default Index;