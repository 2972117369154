import Background from "../components/background";
import VerticalCenteredContainer from "../components/vertical-centered-container";
import Container from "../components/container";
import Snackbar from "../components/snackbar";
import { Link, useNavigate } from "react-router-dom";

import { useRef, useState } from "react";

import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup, getIdToken } from "firebase/auth";
import { getLoginError } from "../scripts/getAuthError";

import requireNoAuth from "../scripts/requireNoAuth";
import AuthForm from "../components/auth-form";

import createUser from "../scripts/createUser";

function Login() {
    const [errorText, setErrorText] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [loading, setLoading] = useState(false); //Processing

    const auth = getAuth();
    const navigate = useNavigate();


    const showError = (text) => {
        setErrorText(text);
        setShowSnackbar(true);
    };

    const loginUserPassword = async (email, password) => {
        if (email == '') {
            showError('Please enter an email');
            return;
        } else if (password == '') {
            showError('Please enter a password');
            return;
        }

        let user;

        try {
            //Firebase
            user = await signInWithEmailAndPassword(auth, email, password);
            setLoading(true);
        } catch (err) {
            showError(getLoginError(err.code));
        }
    };

    const loginGoogle = async () => {
        let user;
        try {
            user = await signInWithPopup(auth, new GoogleAuthProvider());
            setLoading(true);
        } catch (err) {
            if (err.code != 'auth/popup-closed-by-user') {
                showError(getLoginError(err.code));
            }
        }
    }

    return (
        <>
            <Background />
            <Snackbar text={errorText} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} />
            <VerticalCenteredContainer>
                <Container>
                    <div className="text-center mb-5">
                        <div className="text-4xl">Log in</div>
                        <div className="text-3xl">Kovar's Bunkai</div>
                    </div>
                    <AuthForm
                        disabled={loading}
                        className='mb-9'
                        onSubmitGoogle={loginGoogle}
                        onSubmitUserPassword={(email, password) => loginUserPassword(email, password)}
                    />
                    <div className="text-lg text-center">New to Bunkai? <Link to="/signup" className="underline">Sign up</Link></div>
                </Container>
            </VerticalCenteredContainer>
        </>
    );
}

export default requireNoAuth(Login);