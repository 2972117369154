import buttonClickSound from '../assets/button-click2.mp3';
import useSound from 'use-sound';

function VideoCard ({ title, subtitle, thumbnail, selected, onClick }) {
    let [playButtonClick] = useSound(buttonClickSound, { volume: 0.25 });

    let handleClick = () => {
        if (!selected) {
            playButtonClick();
        }
        onClick();
    };

    return (
        <div className={`flex justify-start items-center p-2 cursor-pointer ${selected && 'bg-red-700'}`} onClick={handleClick}>
            <img src={thumbnail} className="aspect-video w-1/3 me-5" />
            <div>
                <div className="text-2xl leading-none">{ title }</div>
                <div className="text-lg text-zinc-400">{ subtitle }</div>
            </div>
        </div>
    );
}

export default VideoCard;