import AppTitle from "../components/app-title";

import PrimaryButton from "../components/primary-button";
import VerticalCenteredContainer from "../components/vertical-centered-container";
import Container from "../components/container";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import requireAuth from "../scripts/requireAuth";
import waitForAuthLoad from "../scripts/waitForAuthLoad";

import { TypeAnimation } from 'react-type-animation';

function NewTitle() {
    const auth = getAuth();
    const navigate = useNavigate();


    const handleClick = () => {
        navigate('/home');
    };

    const [title, setTitle] = useState('');
    const [animationIndex, setAnimationIndex] = useState(0);
    /*
        0 - 'new title'
        1 - text moves up
        2 - typing animation
        3 - button fade in
    */

    const animate = async () => {
        const sleep = async (t) => new Promise(resolve => setTimeout(resolve, t));

        await sleep(1000);
        setAnimationIndex(1);
        await sleep(1000);
        setAnimationIndex(2);
        //Set animation index to 3 is handled by TypeAnimation
    };

    useEffect(() => {
        async function init() {
            await waitForAuthLoad();

            let db = getFirestore();
            let docRef = doc(db, 'users', auth.currentUser.uid);

            let points = (await getDoc(docRef)).data().points;

            let titleIndex = Math.floor(points / 50);


            let titlesQuery = query(collection(db, 'titles'), where('index', '==', titleIndex));
            let t = (await getDocs(titlesQuery)).docs[0].data().title;


            setTitle(t);

            await animate();
        }

        init();
    }, []);

    return (
        <VerticalCenteredContainer className='bg-stone-500'>
            <Container className='text-center'>
                <div className="text-3xl mb-2">NEW TITLE UNLOCKED</div>
                <div className='transition-all duration-500 ease-in-out overflow-clip' style={{ maxHeight: (animationIndex == 0 ? '0' : '300px') }}>
                    <div className="text-6xl text-yellow-300 drop-shadow-retro-md mb-12">
                        {
                            animationIndex >= 2 ?
                            <TypeAnimation
                                sequence={[
                                    '',
                                    `"${title}"`,
                                    750,
                                    () => setAnimationIndex(3)
                                ]}
                                speed={20}
                                cursor={false}
                            /> : <div>&nbsp;</div>
                        }
                    </div>
                    <PrimaryButton onClick={handleClick} disabled={animationIndex != 3} className={`transition-opacity duration-300 ${animationIndex == 3 ? 'opacity-100' : 'opacity-0'}`}>CONTINUE</PrimaryButton>
                </div>
            </Container>
        </VerticalCenteredContainer>
    );
}

export default requireAuth(NewTitle);