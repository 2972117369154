function Close({ onClick }) {
    return (
        <div className='cursor-pointer' onClick={onClick}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="10.6675" y="6.39966" width="3.19999" height="5.33332" transform="rotate(90 10.6675 6.39966)" fill="#E4E4E7" />
                <rect x="8.00049" y="9.59961" width="3.19999" height="5.33332" transform="rotate(90 8.00049 9.59961)" fill="#E4E4E7" />
                <rect width="3.19999" height="5.33332" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 8.00049 6.39966)" fill="#E4E4E7" />
                <rect x="5.33301" y="12.7993" width="3.19999" height="5.33332" transform="rotate(90 5.33301 12.7993)" fill="#E4E4E7" />
                <rect width="3.19999" height="5.33332" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 5.33301 3.19995)" fill="#E4E4E7" />
                <rect x="13.3325" y="9.59961" width="3.19999" height="5.33332" transform="rotate(90 13.3325 9.59961)" fill="#E4E4E7" />
                <rect width="3.19999" height="5.33332" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 13.3325 6.39966)" fill="#E4E4E7" />
                <rect x="16" y="12.7993" width="3.19999" height="5.33332" transform="rotate(90 16 12.7993)" fill="#E4E4E7" />
                <rect width="3.19999" height="5.33332" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 16 3.19995)" fill="#E4E4E7" />
            </svg>
        </div>
    );
}

export default Close;