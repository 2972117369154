import { useState } from "react";

function Radio({ group, value, display, onChange, initialChecked }) {
    return (
        <div>
            <input id={`radio-${group}-${value}`} type="radio" name={group} value={value} className="hidden peer" defaultChecked={initialChecked} onChange={onChange} />
            <label for={`radio-${group}-${value}`} className="block cursor-pointer max-w-[256px] mx-auto text-2xl py-2 bg-stone-500 peer-checked:bg-red-700 transition-colors">{display}</label>
        </div>
    );
}

export default Radio;