import PrimaryButton from "../../components/primary-button";

import img1 from '../../assets/welcome/1.png';
import img2 from '../../assets/welcome/2.png';
import img3 from '../../assets/welcome/3.png';

function Intro({ next }) {
    return (
        <div>
            <div className="text-4xl text-center mb-16">Welcome to Bunkai!</div>
            <div className="grid grid-cols-[72px_1fr] grid-rows-3 grid-flow-col auto-cols-max items-center gap-x-12 gap-y-12 mb-20">
                <div className="justify-self-center"><img src={img1} /></div>
                <div className="justify-self-center"><img src={img2} /></div>
                <div className="justify-self-center"><img src={img3} /></div>
                <div className="text-3xl">SELECT YOUR BELT RANK</div>
                <div className="text-3xl">WATCH CURRICULUM VIDEOS</div>
                <div className="text-3xl">EARN TITLE REWARDS</div>
            </div>
            <div>
                <PrimaryButton onClick={next}>NEXT</PrimaryButton>
            </div>
        </div>
    );
}

export default Intro;