import ContainerLarge from "./container-lg";
import logo from '../assets/logo.png';
import Arrow from './arrow';

import { useEffect, useRef, useState } from "react";

import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import signoutFederatedBBP from "../scripts/signoutFederatedBBP";
import { Link } from "react-router-dom";

import buttonClickSound from '../assets/button-click2.mp3';
import useSound from 'use-sound';

function Nav() {
    const auth = getAuth();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const userName = useRef(auth.currentUser.displayName || auth.currentUser.email);

    const navigate = useNavigate();

    const [playButtonClick] = useSound(buttonClickSound, { volume: 0.25 });

    const logout = async () => {
        playButtonClick();
        
        await signOut(auth);

        await signoutFederatedBBP();

        navigate('/login');
    };

    const handleDropdownToggle = () => {
        playButtonClick();
        setDropdownOpen(!dropdownOpen);
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
        let handleMousedown = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                console.log('clicked outside');
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleMousedown);

        return () => {
            document.removeEventListener('mousedown', handleMousedown);
        };
    }, [dropdownRef]);

    return (
        <ContainerLarge className='relative z-20'>
            <div className="flex justify-between items-center">
                <img src={logo} alt="Kovar's Bunkai logo" className="w-9" />

                <div className="relative" ref={dropdownRef}>
                    <div className="flex items-center cursor-pointer" onClick={handleDropdownToggle}>
                        <div className="text-2xl me-2 truncate max-w-[33vw]">{userName.current}</div>
                        <Arrow className={dropdownOpen && 'rotate-180'} />
                    </div>
                    <div className={`absolute top-full right-0 w-fit bg-stone-500 px-5 py-4 text-xl text-end ${!dropdownOpen && 'hidden'}`}>
                        <div className="flex flex-col gap-y-3 text-start">
                            <Link to='/account' onClick={playButtonClick}>Account</Link>
                            <button className="appearance-none block" onClick={logout}>Log out</button>
                        </div>
                    </div>
                </div>
            </div>
        </ContainerLarge>
    );
}

export default Nav;