import VerticalCenteredContainer from "../components/vertical-centered-container";
import Container from "../components/container";
import Intro from "../components/welcome/intro";
import Form from "../components/welcome/form";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Welcome() {
    let [step, setStep] = useState(0);

    let navigate = useNavigate();
    
    return (
        <div>
            <VerticalCenteredContainer>
                <Container>
                    { step == 0 && <Intro next={() => setStep(1)} />}
                    { step == 1 && <Form next={() => navigate('/home')} /> }
                </Container>
            </VerticalCenteredContainer>
        </div>
    );
}

export default Welcome;