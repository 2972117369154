import Background from "../components/background";
import VerticalCenteredContainer from "../components/vertical-centered-container";
import Container from "../components/container";
import Snackbar from "../components/snackbar";
import AuthForm from './../components/auth-form'

import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getSignupError } from "../scripts/getAuthError";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import dayjs from "dayjs";
import createUser from "../scripts/createUser";

function Login() {
    const [errorText, setErrorText] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [loading, setLoading] = useState(false); //Processing


    const auth = getAuth();
    const navigate = useNavigate();


    const showError = (text) => {
        setErrorText(text);
        setShowSnackbar(true);
    };

    const signupUserPassword = async (email, password) => {
        if (email == '') {
            showError('Please enter an email');
            return;
        } else if (password == '') {
            showError('Please enter a password');
            return;
        }

        try {
            let user = await createUserWithEmailAndPassword(auth, email, password);
            console.log(user.user);
            setLoading(true);
        } catch (err) {
            showError(getSignupError(err.code));
        }
    };

    const signupGoogle = async () => {
        try {
            let user = await signInWithPopup(auth, new GoogleAuthProvider())
            setLoading(true);
        } catch (err) {
            if (err.code != 'auth/popup-closed-by-user') {
                showError(getSignupError(err.code));
            }
        }
    }

    return (
        <>
            <Background />
            <Snackbar text={errorText} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} />
            <VerticalCenteredContainer>
                <Container>
                    <div className="text-center mb-5">
                        <div className="text-4xl">Sign up</div>
                        <div className="text-3xl">Kovar's Bunkai</div>
                    </div>
                    <AuthForm
                        disabled={loading}
                        className='mb-9'
                        googleText='Sign up with Google'
                        onSubmitGoogle={signupGoogle}
                        onSubmitUserPassword={(email, password) => signupUserPassword(email, password)}
                    />
                    <div className="text-lg text-center">Have an account? <Link to="/login" className="underline">Log in</Link></div>
                </Container>
            </VerticalCenteredContainer>
        </>
    );
}

export default Login;