import { Auth } from 'aws-amplify';
import { getAuth, getIdToken, getIdTokenResult } from 'firebase/auth';

import dayjs from 'dayjs';
import waitForAuthLoad from './waitForAuthLoad';

export async function getFederatedSigninObject() {
    //Wait for onauthstatechanged to finish
    await waitForAuthLoad();

    let user = getAuth().currentUser;

    let expirationTimeString = (await getIdTokenResult(user)).expirationTime;

    return {
        token: await getIdToken(user),
        expires_at: dayjs(expirationTimeString).unix()
    };
}

export default async function () {
    try {
        await Auth.currentAuthenticatedUser();
    } catch (err) {
        if (err == 'The user is not authenticated') {
            let result = await Auth.federatedSignIn(
                'securetoken.google.com/kovars-bunkai',
                await getFederatedSigninObject(),
                { name: getAuth().currentUser.displayName }
            );
            console.log(result);
        } else {
            throw err;
        }
    }
}