function ArrowFlat({ className }) {
    return (
        <svg className={className} width="35" height="10" viewBox="0 0 35 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="5" height="3.57143" transform="matrix(-1 0 0 1 20 6.42847)" fill="#B91C1C" />
            <rect width="5" height="3.57143" transform="matrix(-1 0 0 1 25 4.28564)" fill="#B91C1C" />
            <rect x="10" y="4.28564" width="5" height="3.57143" fill="#B91C1C" />
            <rect width="5" height="3.57143" transform="matrix(-1 0 0 1 30 2.14282)" fill="#B91C1C" />
            <rect x="5" y="2.14282" width="5" height="3.57143" fill="#B91C1C" />
            <rect width="5" height="3.57143" transform="matrix(-1 0 0 1 35 0)" fill="#B91C1C" />
            <rect width="5" height="3.57143" fill="#B91C1C" />
        </svg>
    );
}

export default ArrowFlat;