import { useEffect, useState } from "react";
import Container from "./container";

import { motion } from "framer-motion";

import Close from "./close";

function Snackbar({ text, showSnackbar, setShowSnackbar }) {

    return (
        <motion.div
            className={`absolute bottom-0 mb-32 w-full z-20`}
            variants={{
                hidden: {
                    opacity: 0,
                    y: 100
                },
                visible: {
                    opacity: 1,
                    y: 0
                }
            }}
            initial={'hidden'}
            animate={showSnackbar ? 'visible' : 'hidden'}
        >
            <Container>
                <div className="bg-stone-500 drop-shadow-retro-dark-lg px-5 py-4 text-2xl flex items-center justify-between">
                    <div>{text}</div>
                    <Close onClick={() => setShowSnackbar(false)} />
                </div>
            </Container>
        </motion.div>
    );
}

export default Snackbar;