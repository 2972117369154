import Radio from "./radio";
import PrimaryButton from "../primary-button";

import { useState } from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import buttonClickSound from '../../assets/button-click2.mp3';
import useSound from 'use-sound';

function Form ({ next, initialAccountType='' }) {
    let [accountType, setAccountType] = useState(initialAccountType);

    let [playButtonClick] = useSound(buttonClickSound, { volume: 0.25 });

    let handleRadioSelect = (value) => {
        playButtonClick();
        setAccountType(value);
    };
    
    let submit = async () => {
        let db = getFirestore();
        let docRef = doc(db, 'users', getAuth().currentUser.uid);
        await updateDoc(docRef, {
            accountType: accountType
        });

        next();
    };

    return (
        <div className="text-center">
            <div className="text-4xl mb-16">Set up your account</div>
            <div className="text-3xl mb-14">I am a...</div>
            <div className="flex flex-col gap-7 mb-32">
                <Radio group='account-type' value='jr' display='Junior/Lil Sam' initialChecked={initialAccountType == 'jr'} onChange={() => handleRadioSelect('jr')} />
                <Radio group='account-type' value='adult' display='Teen/Adult' initialChecked={initialAccountType == 'adult'} onChange={() => handleRadioSelect('adult')} />
            </div>
            <div>
                <PrimaryButton onClick={submit} disabled={accountType == ''}>LET'S DO THIS</PrimaryButton>
            </div>
        </div>
    )
}

export default Form;