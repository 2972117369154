import ArrowFlat from '../components/arrow-flat';

import AppTitle from '../components/app-title';

import ProgressBar from '../components/progress-bar';
import Scrollbar from './scrollbar';
import { useState, useEffect, useRef } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import ModalContainer from './modal-container';
import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { useLocation, useNavigate } from 'react-router-dom';

import gunShotSound from '../assets/8-bit-gunshot-2.mp3';
import useSound from 'use-sound';

function HomeModal() {
    let [open, setOpen] = useState(false);

    let [titles, setTitles] = useState([]);

    let [points, setPoints] = useState(0);

    let [titleIndex, setTitleIndex] = useState(0);

    const closedModalRef = useRef(null);

    const [placeholderHeight, setPlaceholderHeight] = useState(0);

    let location = useLocation();
    let navigate = useNavigate();

    let [earnedPoints, setEarnedPoints] = useState(location.state?.earnedPoints || false);

    useEffect(() => {
        if (location.state?.earnedPoints) {
            //Don't show animation on re-render
            window.history.replaceState({}, document.title);
            setTimeout(() => {
                setEarnedPoints(false);
            }, 2500);
        }
    }, []);

    useEffect(() => {
        async function init() {
            let db = getFirestore();

            let t = (await getDocs(collection(db, 'titles'))).docs;

            console.log(t);

            t = t.sort((a, b) => {
                return a.data().index - b.data().index;
            }).map(title => {
                return title.data().title
            });

            setTitles(t);

            let p = (await getDoc(doc(db, 'users', getAuth().currentUser.uid))).data().points;

            setPoints(p);

            setTitleIndex(Math.min(Math.floor(p / 50), t.length - 1));

            setPlaceholderHeight(closedModalRef.current.clientHeight);
        }

        init();
    }, []);

    let [playGunShot] = useSound(gunShotSound, { volume: 0.25 });

    let handleOpen = () => {
        playGunShot();
        setOpen(!open);
    };


    // let titles = [
    //     { name : 'YAISH TAH', earned : false},
    //  { name : 'ELK GROVE BOYS', earned : false},
    //     { name : 'FLAWLESS VICTORY', earned : true},
    //     { name : '[???]', earned : false},
    //     { name : '[???]', earned : false},
    //     { name : '[???]', earned : false},
    //     { name : '[???]', earned : false}
    // ];

    let openModal = (
        <ModalContainer className='cursor-pointer'>

            <div className="text-2xl text-center mb-5">TITLES</div>
            <Scrollbar
                className='overflow-auto h-56 mb-2'
                type='vertical'
            >
                {
                    titles.map((title, index) => {
                        if (titleIndex >= index) {
                            return (<AppTitle className='truncate' key={index}>"{title}"</AppTitle>);
                        } else {
                            return (<div className="text-3xl truncate" key={index}>"{title}"</div>);
                        }
                    })
                }
            </Scrollbar>
            <div className='text-lg text-center leading-none'>You earn 10 points for every day you use Bunkai.</div>
            <div className='h-5 flex items-center justify-center'>
                <ArrowFlat className='rotate-180' />
            </div>
        </ModalContainer>
    );

    let closedModal = (
        <ModalContainer className='cursor-pointer'>

            <div className='relative pb-5'>
                <AppTitle className='text-center'>"{titles[titleIndex] || '...'}"</AppTitle>
                {
                    (earnedPoints) &&
                    <motion.div
                        className='z-30 absolute bottom-0 right-0 text-yellow-300 text-3xl'
                        animate={{
                            y: [0, null, null, -50],
                            opacity: [0, 1, null, 0],
                            scale: [2, 3, 1, null]
                        }}
                        transition={{
                            delay: 0.5,
                            duration: 2,
                            times: [0, 0.1, 0.6, 1],
                            ease: 'anticipate'
                        }}
                    >
                        +10
                    </motion.div>
                }
            </div>


            <div className="relative h-2 mb-1">
                <ProgressBar color='bg-zinc-200' className='absolute w-full' />
                <ProgressBar
                    color='bg-red-700'
                    className='absolute transition-all'
                    style={{
                        width: `${(points - (50 * titles.length)) >= 0 && points != 0 ? '100' : ((points % 50) / 50 * 100)}%`
                    }}
                />

            </div>
            <div className='text-lg text-end'>{titleIndex == titles.length - 1 ? (points - (50 * (titles.length - 1))) : `${points % 50}/50`} points</div>

            <div className='h-5 flex items-center justify-center'>
                <ArrowFlat />
            </div>
        </ModalContainer>
    );

    return (
        <div onClick={handleOpen}>
            <AnimatePresence mode='wait' initial={false}>
                {
                    open ? (
                        <>
                            <motion.div
                                className='absolute left-0 w-full'
                                key='open'
                                initial={{ height: 'initial', top: 'initial' }}
                                animate={{ height: '100%', top: 0 }}
                                exit={{ height: 'initial', top: 'initial' }}
                                transition={{ ease: 'linear', duration: 0.15 }}
                            >
                                <div className='flex justify-center items-center h-full'>
                                    <motion.div
                                        className="origin-top"
                                        key='open-inner'
                                        initial={{ scaleY: 0.33, opacity: 0 }}
                                        animate={{ scaleY: 1, opacity: 1 }}
                                        exit={{ scaleY: 0.33, opacity: 0 }}
                                        transition={{ ease: 'linear', duration: 0.15 }}
                                    >{openModal}</motion.div>
                                    {/*
                                    This works but it's stretching everything -- just want it to go forwards and back
                                    For that we'll need height
                                */}
                                </div>
                            </motion.div>

                            <div style={{ height: `${placeholderHeight}px` }} />
                        </>
                    ) : (
                        <motion.div
                            key='closed'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ ease: 'linear', duration: 0.15 }}
                            ref={closedModalRef}
                        >
                            {closedModal}
                        </motion.div>
                    )
                }
            </AnimatePresence>
        </div>
    );
}

export default HomeModal;