import Arrow from "../components/arrow";
import Nav from "../components/nav";
import Container from "../components/container";

import { Link, useParams } from "react-router-dom";

import ReactPlayer from "react-player";
import VideoCard from "../components/video-card";

import { useEffect, useState } from "react";

import { getFirestore, getDoc, doc } from "firebase/firestore";

import requireAuth from '../scripts/requireAuth';
import { API } from "aws-amplify";

import axios from "axios";

import buttonClickSound from '../assets/button-click2.mp3';
import useSound from 'use-sound';

function Rank() {
    //Using firestore, get the specific rank document

    let params = useParams();

    let [rankName, setRankName] = useState('');
    let [videos, setVideos] = useState([]);

    let [playButtonClick] = useSound(buttonClickSound, { volume: 0.25 });

    useEffect(() => {
        async function init() {
            let db = getFirestore();
            let rank = await getDoc(doc(db, 'ranks', params.rankID));

            setRankName(rank.data().name);

            let v = await Promise.all(rank.data().videos.map(async videoID => {
                let video = await API.get('bbp', `/videos/${videoID}/`);
                let vimeoData = (await axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${video.URL}`)).data;
                
                console.log(vimeoData);

                return {
                    ...video, //videoID, name, content, URL
                    length : `${Math.floor(vimeoData.duration / 60)}:${String(vimeoData.duration % 60).padStart(2, '0')}`,
                    thumbnail : vimeoData.thumbnail_url
                };
            }));

            setVideos(v);
        }
        init();
    }, []);


    let [selected, setSelected] = useState(0);

    return (
        <div>
            <Nav />
            <Container>
                <div className="mt-12">
                    <Link className="flex items-center justify-start mb-2" to='/home' onClick={playButtonClick}>
                        <Arrow className='rotate-90 me-2' />
                        <div className="text-2xl">{rankName || '...'}</div>
                    </Link>
                    <div className='text-4xl mb-5'>{videos[selected]?.name || '...'}</div>

                    <div className="bg-stone-500">
                        <ReactPlayer
                            url={`https://vimeo.com/${videos[selected]?.URL}`}
                            key={videos[selected]?.URL}
                            className='aspect-video mb-20'
                            width='initial'
                            height='initial'
                            controls={true}
                        />
                    </div>

                    <div>
                        <div className="text-2xl mb-5">More videos</div>
                        <div className="grid grid-cols-1 gap-y-2">
                            {
                                videos.map((video, index) => {
                                    return (
                                        <VideoCard
                                            key={video.videoID}
                                            title={video.name}
                                            subtitle={video.length}
                                            thumbnail={video.thumbnail}
                                            selected={index === selected}
                                            onClick={() => setSelected(index)}
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default requireAuth(Rank);