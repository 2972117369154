import { Link, useNavigate } from "react-router-dom";

import { getIdToken, getAuth } from "firebase/auth";

import { API, Auth } from 'aws-amplify';
import { useEffect } from "react";

function Root() {
    let navigate = useNavigate();
    useEffect(() => {
        navigate('/home');
    }, []);
    //Auth.federatedSignIn();
    return (
        <div></div>
    );
}

export default Root;