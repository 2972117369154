import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import dayjs from 'dayjs';

export default async function () {
    //Idempotent, so can call from login with google
    let auth = getAuth();

    let db = getFirestore();

    let docRef = doc(db, 'users', auth.currentUser.uid);

    if ((await getDoc(docRef)).data()) {
        console.log('user already exists');
        return;
    };

    await setDoc(docRef, {
        lastLoginDate: dayjs().format('YYYY-MM-DD'),
        points: 0,
        accountType : null
    });
}