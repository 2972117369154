import VerticalCenteredContainer from "../components/vertical-centered-container";
import Container from "../components/container";
import Form from "../components/welcome/form";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import getAccountType from "../scripts/getAccountType";
import requireAuth from "../scripts/requireAuth";

function Account() {
    let navigate = useNavigate();

    let [initialAccountType, setInitialAccountType] = useState(null);

    useEffect(() => {
        async function init() {
            setInitialAccountType(await getAccountType());
        }

        init();
    }, []);

    return (
        <div>
            <VerticalCenteredContainer>
                <Container>
                    {initialAccountType && <Form next={() => navigate('/home')} initialAccountType={initialAccountType} />}
                </Container>
            </VerticalCenteredContainer>
        </div>
    );
}

export default requireAuth(Account);